import * as React from "react";
import * as Rebass from 'rebass';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button'
import * as Color from 'color'
import Link from '@material-ui/core/Link';
import { Link as RouterLink, GatsbyLinkProps, navigate, useStaticQuery, graphql } from 'gatsby'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Wrapper } from '../../components/wrapper';
import { HR } from "../../components/hr";
import { Grid } from "@material-ui/core";

// const PageTitle = styled(Rebass.Text).attrs({
//   fontFamily: 'Avenir',
//   fontSize: [5]
// })``

// const PageHeader = styled(Rebass.Flex).attrs({
//   flexDirection: 'column',
//   py: 4,
//   justifyContent: 'flex-start'
// })`
//   width: 100%;
//   max-width: 1000px;
// `

// const ResourceCard = styled(Rebass.Card).attrs({
//   fontSize: 5,
//   fontWeight: 'bold',
//   width: [ 1, 1, 1/2 ],
//   p: 5,
//   my: 3,
//   // bg: '#f6f6ff',
//   borderRadius: 5,
//   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)'
// })``

// const CardContainer = styled(Rebass.Flex).attrs({
//   flexDirection: 'row',
//   justifyContent: 'space-between'
// })``

// const SubHeader = styled(Rebass.Text).attrs({
//   fontFamily: 'Avenir',
//   fontWeight: 300,
//   color: '#071D3B'
// })``

const BACKGROUND_COLOR = '#F5F7FA';

const Wrapper = styled(Box)({
  backgroundColor: BACKGROUND_COLOR
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #efefef',
      zIndex: 999
    },
    icon: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0
      }
    },
    container: {
      paddingLeft: 0,
      paddingRight: 0
    }
  })

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

const LinkComponent = React.forwardRef<HTMLAnchorElement, Omit<GatsbyLinkProps<{}>, 'ref'>>((props, ref) => (
  // @ts-ignore
  <RouterLink innerRef={ref} {...props} />
))

const PrimaryText = styled(Rebass.Text)`
  font-family: Avenir;
  font-weight: 300;
  color: #071D3B;
  letter-spacing: 0;
`

PrimaryText.defaultProps = {
  fontSize: 5
}

const SecondaryText = styled(Rebass.Text)`
  opacity: 0.5;
  font-family: 'Open Sans';
  font-weight: 300;
  /* font-size: 16px; */
  color: #071D3B;
  letter-spacing: 0;
  line-height: 1.5;
  /* line-height: 27px; */
`

SecondaryText.defaultProps = {
  fontSize: [3],
  mt: 2
}

export default () => {
  return (
    <Layout>
      <Container maxWidth="md">

        <SEO title="Privacy Policy" />

        <Box mt={3}>
          <Box my={4}>
            <Typography variant='h5' style={{textAlign: 'center'}}>               			                   		

                PATIENT CONSENT FORM: FOR COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION

            </Typography>
            <br/>

            <Typography>

                Privacy of your personal information is an important part of our office providing you with quality dental care. We understand the importance of protecting your personal information. We are committed to collecting, using and disclosing your personal information responsibly. We also try to be as open and transparent as possible about the way we handle your personal information. It is important to us to provide this service to our patients.

            </Typography>
            <br/>
            <Typography>
                In this office, Dr. Aram Mohajeri acts as the Privacy Information Officer.
            </Typography>
            <br/>
            <Typography>            
                        
                All staff members who come in contact with your personal information are aware of the sensitive nature of the information that you have disclosed to us. They are all trained in the appropriate uses and protection of your information. 

            </Typography>
            <br/>

            <Typography>

                Attached to this consent form, we have outlined what our office is doing to ensure that:

            </Typography>

            <Typography style={{marginLeft: '2em'}}>
              • only necessary information is collected about you;
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
              • we only share your information with your consent;
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
              • storage, retention and destruction of your personal information complies with existing legislation, and privacy protection protocols;
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
              • our privacy protocols comply with privacy legislation, standards of our regulatory body, 
                the Royal College of Dental Surgeons of Ontario, and the law.
            </Typography>
            <br/>
            <Typography>

                Do not hesitate to discuss our policies with me or any member of our office staff. 

            </Typography>
            <br/>

            <Typography>

                Please be assured that every staff person in our office is committed to ensuring that you receive the best quality dental care.

            </Typography>
            <br/><br/>

            <Typography variant='h5' style={{textAlign: 'center'}}>               			                   		

            How Our Office Collects, Uses and Discloses Patients’ 
                Personal Information
            </Typography>
            <br/><br/>

            <Typography>
                
                Our office understands the importance of protecting your personal information. To help you understand how we are doing that, we have outlined here how our office is using and disclosing your information. 

            </Typography>
            <br/>

            <Typography>
        
                This office will collect, use and disclose information about you for the following purposes:

            </Typography>

            <Typography style={{marginLeft: '2em'}}>
            • to deliver safe and efficient patient care
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to identify and to ensure continuous high quality service
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to assess your health needs
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to deliver safe and efficient patient care
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to provide health care
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to advise you of treatment options 
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to enable us to contact you
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to establish and maintain communication with you
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to offer and provide treatment, care and services in relationship to the oral and maxillofacial complex and dental care generally
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to communicate with other treating health-care providers, including specialists and general dentists who are the referring dentists and/or peripheral dentists
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to allow us to maintain communication and contact with you to distribute health-care information and to book and confirm appointments
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to allow us to efficiently follow-up for treatment, care and billing
            </Typography>  
            <Typography style={{marginLeft: '2em'}}>
            • for teaching and demonstrating purposes on an anonymous basis
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to complete and submit dental claims for third party adjudication and payment
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
              • to comply with legal and regulatory requirements, including the delivery of patients’
                charts and records to the Royal College of Dental Surgeons of Ontario in a timely fashion, when required, according to the provisions of the Regulated Health Professions Act      
            </Typography>   
            <Typography style={{marginLeft: '2em'}}>
            • to comply with agreements/undertakings entered into voluntarily by the member with the Royal College of Dental Surgeons of Ontario, including the delivery and/or review of patients’ charts and records to the College in a timely fashion for regulatory and monitoring purposes
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to permit potential purchasers, practice brokers or advisors to evaluate the dental practice
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to allow potential purchasers, practice brokers or advisors to conduct an audit in preparation for a practice sale
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to deliver your charts and records to the dentist’s insurance carrier to enable the insurance company to assess liability and quantify damages, if any
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to prepare materials for the Health Professions Appeal and Review Board (HPARB)
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to invoice for goods and services
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to process credit card payments
            </Typography>  
            <Typography style={{marginLeft: '2em'}}>
            • to collect unpaid accounts
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to assist this office to comply with all regulatory requirements
            </Typography>
            <Typography style={{marginLeft: '2em'}}>
            • to comply generally with the law
            </Typography>
            <Typography>                   
              By signing the consent section of this Patient Consent Form, you have agreed that you have given your informed consent to the collection, use and/or disclosure of your personal information for the purposes that are listed. If a new purpose arises for the use and/or disclosure of your personal information, we will seek your approval in advance.
              Your information may be accessed by regulatory authorities under the terms of the Regulated Health Professions Act (RHPA) for the purposes of the Royal College of Dental Surgeons of Ontario fulfilling its mandate under the RHPA, and for the defence of a legal issue.
              Our office will not under any conditions supply your insurer with your confidential medical history. In the event this kind of a request is made, we will forward the information directly to you for review, and for your specific consent. 
              When unusual requests are received, we will contact you for permission to release such information. We may also advise you if such a release is inappropriate.
              You may withdraw your consent for use or disclosure of your personal information, and we will explain the ramifications of that decision, and the process.
            </Typography>
          </Box>

        </Box>
      </Container>
    </Layout>
  );
}
